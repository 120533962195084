.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #fff;
  font-size: 18px;
  line-height: 1.44444444;
  letter-spacing: 0.03em;
  border: 1px solid #000;
  cursor: pointer;
  color: #000 !important;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 4px 14px;
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #E59E6C, #E59E6C);
  transition: all 0.3s;
}
@media (max-width: 1279px) {
  .button {
    font-size: 16px;
    line-height: 1.5;
  }
}
.button:hover {
  background-size: 100% 100%;
}
.section--two .subcontent.subcontent--1 {
  max-width: calc((((100% - (6 * 14px)) /4) * 3) + (4 * 14px));
}
.area--one .unitThree .unit__content {
  margin-top: 40px;
}
.area--one .unitThree--imageWidth2-3 .unit__background {
  width: calc((100%/1496*940) - 14px);
}
.area--one .unitThree--imageWidth2-3 .unit__content {
  width: calc((100%/1496*556) - 14px);
}
.area--one .unitFour {
  width: calc((100% / 3) - 28px);
}
.area--one .unit--fold div.more .part--1-4 {
  width: calc(25% - 28px);
}
.unit--miniGallery .part {
  width: calc(25% - 28px);
  height: 240px;
}
.unit--miniGallery .part:first-child {
  width: calc(50% - 28px);
  height: 508px;
}
/*# sourceMappingURL=./screen-large.css.map */